import { validateSearchProfileSection } from "@lobby/core/src/app";
import { ModalManager, type ProfileSection } from "@lobby/core/src/components";
import type { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Navigate, Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { ErrorComponent } from "widget/error-component";

type GlobalSearch = {
  modal: string;
  cid: number | string;
  pid: number;
  game: string;
  redirect: string;
  profileSection: ProfileSection;
};

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: RootComponent,
  onError: console.error,
  errorComponent: ErrorComponent,
  notFoundComponent: () => <Navigate to="/" replace />,
  validateSearch: (search): Partial<GlobalSearch> => ({
    modal: search.modal as string,
    cid: search.cid as number,
    pid: search.pid as number,
    game: search.game as string,
    redirect: search.redirect as string,
    profileSection: validateSearchProfileSection(search) as ProfileSection,
  }),
});

function RootComponent() {
  return (
    <>
      <ModalManager />
      <div id="message-container" />

      <Outlet />
      <ReactQueryDevtools buttonPosition="bottom-left" />
    </>
  );
}
