import { FortuneWheel, Lobby } from "@lobby/core/src/entities";
import { formatTime, useFortuneWheelCountdown } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import fortuneWheelBtnImg from "assets/fw-btn-bg.png";

export function FortuneWheelButton() {
  const { $t } = useTranslate();
  const { data: lobbyData } = Lobby.useLobby();
  const { data: settingsData } = FortuneWheel.useWheelSettings();

  const countdown = useFortuneWheelCountdown(lobbyData?.fortuneWheel?.nextSpinTime as number);

  const isCountdownActive = countdown > 0;

  if (settingsData?.error) {
    return null;
  }

  return (
    <div className="h-[5.25rem] rounded-sm bg-java/30">
      <div className="flex h-full items-center justify-between gap-4 p-3">
        <div className="relative size-24 min-w-24">
          <img
            className="absolute size-full animate-step-spin object-cover"
            src={fortuneWheelBtnImg}
            alt="fortune-wheel-preview"
            width="100%"
            height="100%"
          />
        </div>

        <Link
          className={`${isCountdownActive ? "bg-transparent" : "bg-black"} no-underline h-full flex-center grow rounded-rounded border-2 border-java px-3 py-2 hover:drop-shadow-[0_0_0.25rem_#13c7bc]`}
          to="/fortune-wheel"
        >
          <div
            className={`whitespace-normal text-center font-commissioner font-extrabold text-java uppercase italic leading-5 [text-shadow:0_0_1.25rem_#13c7bc] *:landscape:inline-block ${isCountdownActive ? "text-lg landscape:text-2xl" : "landscape:text-lg"}`}
          >
            {isCountdownActive ? (
              formatTime(countdown)
            ) : (
              <>
                <div>{$t({ defaultMessage: "fortune wheel" })}</div>{" "}
                <div>{$t({ defaultMessage: "ready to spin" })}</div>
              </>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}
